import { css, Global } from '@emotion/react';
import media from './mediaQuery';

const globalStyles = (
  <Global
    styles={css`
      @font-face {
        font-family: 'Pretendard-Light';
        src: url('/fonts/Pretendard-Light.woff2') format('woff2'), url('/fonts/Pretendard-Light.woff') format('woff');
        font-display: swap;
      }
      @font-face {
        font-family: 'Pretendard-Regular';
        src: url('/fonts/Pretendard-Regular.woff2') format('woff2'),
          url('/fonts/Pretendard-Regular.woff') format('woff');
        font-display: swap;
      }
      @font-face {
        font-family: 'Pretendard-SemiBold';
        src: url('/fonts/Pretendard-SemiBold.woff2') format('woff2'),
          url('/fonts/Pretendard-SemiBold.woff') format('woff');
        font-display: swap;
      }
      @font-face {
        font-family: 'Pretendard-Bold';
        src: url('/fonts/Pretendard-Bold.woff2') format('woff2'), url('/fonts/Pretendard-Bold.woff') format('woff');
        font-display: swap;
      }
      @font-face {
        font-family: 'Pretendard-ExtraBold';
        src: url('/fonts/Pretendard-ExtraBold.woff2') format('woff2'),
          url('/fonts/Pretendard-ExtraBold.woff') format('woff');
        font-display: swap;
      }

      @font-face {
        font-family: 'Gilroy-ExtraBold';
        src: url('/fonts/Gilroy-ExtraBold.otf');
        src: url('/fonts/Gilroy-ExtraBold.eot?') format('embedded-opentype'),
          url('/fonts/Gilroy-ExtraBold.woff') format('woff'), url('/fonts/Gilroy-ExtraBold.ttf') format('truetype'),
          url('/fonts/Gilroy-ExtraBold.woff2') format('woff2');
        font-style: normal;
        font-weight: normal;
      }

      @font-face {
        font-family: 'Nanum-Myeongjo';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/NanumMyeongjo-Regular.eot');
        src: url('/fonts/NanumMyeongjo-Regular.eot?#iefix') format('embedded-opentype'),
          url('/fonts/NanumMyeongjo-Regular.woff2') format('woff2'),
          url('/fonts/NanumMyeongjo-Regular.woff') format('woff'),
          url('/fonts/NanumMyeongjo-Regular.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Nanum-Myeongjo-Bold';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/NanumMyeongjo-Bold.eot');
        src: url('/fonts/NanumMyeongjo-Bold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/NanumMyeongjo-Bold.woff2') format('woff2'), url('/fonts/NanumMyeongjo-Bold.woff') format('woff'),
          url('/fonts/NanumMyeongjo-Bold.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Nanum-Myeongjo-ExtraBold';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/NanumMyeongjo-ExtraBold.eot');
        src: url('/fonts/NanumMyeongjo-ExtraBold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/NanumMyeongjo-ExtraBold.woff2') format('woff2'),
          url('/fonts/NanumMyeongjo-ExtraBold.woff') format('woff'),
          url('/fonts/NanumMyeongjo-ExtraBold.ttf') format('truetype');
      }

      #google-tagmanager {
        display: 'none';
        visibility: 'hidden';
      }

      html,
      html h1,
      html h2,
      html h3,
      html h4,
      html h5,
      html h6,
      html a,
      html p,
      html li,
      input,
      textarea,
      span,
      div,
      html,
      body,
      html a {
        margin: 0;
        padding: 0;
        font-family: 'Pretendard-Regular', sans-serif;
      }
      body {
        &.body-scroll-ban {
          overflow: hidden;
          touch-action: none; // for mobile safari
          -ms-touch-action: none; // for mobile safari
        }
        a {
          text-decoration: none;
        }
        button {
          border: none;
          background: none;
          outline: none;
          padding: 0;
          cursor: pointer;
        }
        .page-layout {
          ${media.desktop} {
            border-top: 3px solid #555555;
            width: 944px;
            margin: 56px auto 0;
            padding-top: 24px;
          }
        }
        .page-title {
          color: #333333;
          background-color: #f6f6f6;
          padding: 9px 20px;
          border-bottom: 1px solid #cfcfcf;
          font-weight: normal;
          ${media.mobile} {
            font-size: 20px;
          }
        }
        .mobile {
          ${media.desktop} {
            display: none;
          }
        }
        .desktop {
          ${media.mobile} {
            display: none;
          }
        }
      }
    `}
  />
);
export default globalStyles;
