import { init, track } from '@amplitude/analytics-browser';

const isServer = typeof window === 'undefined';

export const initAmplitude = (amplitudeKey: string): void => {
  if (!isServer && !window.AMPLITUDE_INITIALIZED && amplitudeKey) {
    init(amplitudeKey);
    window.AMPLITUDE_INITIALIZED = true;
  }
};

export const sendAmplitudeEvent = (eventName: string, eventParams: Record<string, any>): void => {
  if (!isServer && window.AMPLITUDE_INITIALIZED) {
    track(eventName, eventParams);
  }
};
