import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { sendAmplitudeEvent } from '../utils/amplitude';
import { useScrollDepth } from '../utils/useScroll';

interface LayoutType {
  children: React.ReactNode;
}

export const Layout: FunctionComponent<LayoutType> = ({ children }: LayoutType) => {
  const router = useRouter();
  if (typeof window !== 'undefined') {
    useScrollDepth(
      [25, 50, 75, 100],
      (percent) => {
        sendAmplitudeEvent('sqr_scroll_page_depth', {
          scroll_depth: percent / 100,
          page_path: decodeURIComponent(router.asPath),
        });
      },
      router.asPath,
    );
  }
  return <>{children}</>;
};
