import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

export const useScroll = () => {
  const [state, setState] = useState({ y: 0 });
  const onScroll = _.throttle(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setState({ y: scrollTop });
  }, 100);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return state;
};

export const useScrollDepth = (
  percentList: number[] = [],
  onReachPercent: (targetPercent: number) => void = () => null,
  pagePath: string,
) => {
  const mapPercentToOnceSent = useRef<Record<number, boolean>>({});
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const onScrollDepth = _.throttle(() => {
      const scroll = screenHeight + window.scrollY;
      const totalScroll = document.documentElement.scrollHeight;
      const percent = (scroll / totalScroll) * 100;
      percentList.forEach((targetPercent: number) => {
        if (percent >= targetPercent && !mapPercentToOnceSent.current[targetPercent]) {
          mapPercentToOnceSent.current[targetPercent] = true;
          onReachPercent(targetPercent);
        }
      });
    }, 200);
    const onResize = _.debounce(() => {
      setScreenHeight(document.documentElement.scrollHeight);
    }, 200);
    window.addEventListener('scroll', onScrollDepth);
    window.addEventListener('resize', onResize);
    return () => {
      mapPercentToOnceSent.current = {};
      window.removeEventListener('scroll', onScrollDepth);
      window.removeEventListener('resize', onResize);
    };
  }, [screenHeight, mapPercentToOnceSent, pagePath]);
};
