import NextApp, { AppProps } from 'next/app';
import { Router } from 'next/router';
import { initAmplitude, sendAmplitudeEvent } from '../utils/amplitude';
import globalStyles from '../shared/styles';
import { BREAK_POINT } from '../shared/mediaQuery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-quill/dist/quill.snow.css';
import { Layout } from '../components/Layout';

//페이지 변경될때 사이에 레이아웃을 유지해준다.
//navigating 페이지들의 state유지해준다.
//componentDidCatch 함수에서 error를 핸들링 할 수 있다.
//데이터를 넣을 수 있다.
//global style을 여기서 넣어줄수 있다.

type componentProps = {
  Component: any;
  pageProps: any;
  authtoken?: string;
};

Router.events.on('routeChangeComplete', (route: string) => {
  const from = localStorage.getItem('prevPath');
  sendAmplitudeEvent('sqr_pageChanged', {
    from: from || '',
    to: `${route}`,
  });
  localStorage.setItem('prevPath', route);
});

export default class App extends NextApp {
  isServer: boolean;

  static async getInitialProps(props: any) {
    const Component = props.Component;
    const ctx = props.ctx;

    let pageProps = {};
    let appProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    } else {
      appProps = await NextApp.getInitialProps(props);
    }

    let userAgent;
    if (props.ctx.req) {
      userAgent = props.ctx.req.headers['user-agent'];
    } else {
      userAgent = navigator.userAgent;
    }
    const isMobile = Boolean(userAgent?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

    return { pageProps, ...appProps, isMobile };
  }

  constructor(props: any) {
    super(props);
    this.isServer = typeof window === 'undefined';
    this.state = {
      isMobile: props.isMobile,
    };
  }

  checkMobile = () => {
    if (!this.isServer) {
      const width = window.innerWidth;

      return (
        width <= BREAK_POINT ||
        Boolean(navigator.userAgent?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i))
      );
    }
    return false;
  };

  handleResize = () => {
    this.setState({
      isMobile: this.checkMobile(),
    });
    return;
  };

  windowAddEvent = () => {
    window.addEventListener('resize', this.handleResize);
    return () => window.removeEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    this.windowAddEvent();
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    });
    this.windowAddEvent();
    this.setState({
      isMobile: this.checkMobile(),
    });

    if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      initAmplitude(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
      const { router }: AppProps = this.props;
      sendAmplitudeEvent('sqr_pageChanged', { to: `${router.asPath}` });
      localStorage.setItem('prevPath', router.asPath);
    }
  }

  render() {
    const { Component, pageProps }: componentProps = this.props;
    const compProps = { ...this.state, ...pageProps };
    return (
      <Layout>
        {globalStyles}
        {/* pageProps 모든 페이지에서 쓸수 있는 props */}
        <Component {...compProps} />
      </Layout>
    );
  }
}
